import { LANDING_IMAGE_URL_MAIN } from '@pages/landing/shared/constant';

export const mainImage = {
  src: `${LANDING_IMAGE_URL_MAIN}/01-kemi-example.gif`,
  width: 1116,
  height: 1440,
};

export const sellerExample = {
  src: `${LANDING_IMAGE_URL_MAIN}/02-1-seller-feature.gif`,
  width: 720,
  height: 480,
};

export const kemiKo = {
  src: `${LANDING_IMAGE_URL_MAIN}/02-2-kemi-ko.png`,
  width: 108,
  height: 97,
};

export const kemiLee = {
  src: `${LANDING_IMAGE_URL_MAIN}/02-3-kemi-lee.png`,
  width: 108,
  height: 97,
};

export const creatorExample = {
  src: `${LANDING_IMAGE_URL_MAIN}/03-1-creator-feature.gif`,
  width: 720,
  height: 720,
};

export const kemiJeon = {
  src: `${LANDING_IMAGE_URL_MAIN}/03-2-kemi-jeon.png`,
  width: 108,
  height: 97,
};

export const kemiMin = {
  src: `${LANDING_IMAGE_URL_MAIN}/03-3-kemi-min.png`,
  width: 108,
  height: 97,
};

export const kemiPreview1 = {
  src: `${LANDING_IMAGE_URL_MAIN}/04-1-kemi-preview-01.png`,
  width: 510,
  height: 1086,
};

export const kemiPreview2 = {
  src: `${LANDING_IMAGE_URL_MAIN}/04-2-kemi-preview-02.png`,
  width: 510,
  height: 1086,
};

export const kemiPreview3 = {
  src: `${LANDING_IMAGE_URL_MAIN}/04-3-kemi-preview-03.png`,
  width: 510,
  height: 1086,
};

export const kemiPreview4 = {
  src: `${LANDING_IMAGE_URL_MAIN}/04-4-kemi-preview-04.png`,
  width: 510,
  height: 1086,
};

export const kemiPreview5 = {
  src: `${LANDING_IMAGE_URL_MAIN}/04-5-kemi-preview-05.png`,
  width: 510,
  height: 1086,
};

export const sellerGIF = {
  src: `${LANDING_IMAGE_URL_MAIN}/05-1-statistics-seller.gif`,
  width: 720,
  height: 360,
};

export const profitGIF = {
  src: `${LANDING_IMAGE_URL_MAIN}/05-2-statistics-profit.gif`,
  width: 720,
  height: 360,
};

export const visitorGIF = {
  src: `${LANDING_IMAGE_URL_MAIN}/05-3-statistics-user.gif`,
  width: 720,
  height: 360,
};

export const kemiExample = {
  src: `${LANDING_IMAGE_URL_MAIN}/06-kemi-example.png`,
  width: 1116,
  height: 2775,
};

export const sellerUSPImage1 = {
  src: `${LANDING_IMAGE_URL_MAIN}/07-1-seller-usp-01.png`,
  width: 720,
  height: 480,
};
export const sellerUSPImage2 = {
  src: `${LANDING_IMAGE_URL_MAIN}/07-2-seller-usp-02.png`,
  width: 720,
  height: 480,
};

export const sellerUSPImage3 = {
  src: `${LANDING_IMAGE_URL_MAIN}/07-3-seller-usp-03.png`,
  width: 720,
  height: 480,
};

export const creatorUSPImage1 = {
  src: `${LANDING_IMAGE_URL_MAIN}/08-1-creator-usp-01.png`,
  width: 720,
  height: 480,
};

export const creatorUSPImage2 = {
  src: `${LANDING_IMAGE_URL_MAIN}/08-2-creator-usp-02.png`,
  width: 720,
  height: 480,
};

export const creatorUSPImage3 = {
  src: `${LANDING_IMAGE_URL_MAIN}/08-3-creator-usp-03.png`,
  width: 720,
  height: 480,
};
