import { useRouter } from 'next/router';

import {
  creatorExample,
  kemiJeon,
  kemiKo,
  kemiLee,
  kemiMin,
  sellerExample,
} from '../images';

import SampleReviewCard from './SampleReviewCard';

import Image from '@global/components/atoms/Image';
import { EVENT_TAG } from '@global/constants';
import { logFirebase } from '@global/service/logger/EventHandler';
import { UserInteractionType } from '@global/types';
import i18n from '@i18n/index';
import LandingButton from '@pages/landing/shared/components/LandingButton';
import ROUTE from '@routers';
import { css, styled } from '@styles/stitches.config';
import { getUserAgent } from '@utils/dom';
import I18n from 'src/i18n/components/I18n';

const SellerAndCreatorType = () => {
  const router = useRouter();

  const handleClickSellerInfoButton = () => {
    logFirebase(
      UserInteractionType.CLICK,
      EVENT_TAG.LANDING.LD_HOME_CLICK_FORSELLER_CLICK,
      {
        device: getUserAgent().device.type ?? 'desktop',
      }
    );

    router.replace(ROUTE.seller);
  };

  const handleClickCreatorInfoButton = () => {
    logFirebase(
      UserInteractionType.CLICK,
      EVENT_TAG.LANDING.LD_HOME_CLICK_FORCREATOR_CLICK,
      {
        device: getUserAgent().device.type ?? 'desktop',
      }
    );

    router.replace(ROUTE.creator);
  };

  return (
    <Container>
      <Section theme={'yellow'}>
        <SampleImage>
          <Image alt={'sellerExample'} {...sellerExample} />
        </SampleImage>
        <SampleReviewCard
          reviewer={i18n.t(
            'k_landing_main_seller_creator_type_card_reviewer_1'
          )}
          reviewerLogo={kemiKo}
        >
          <ReviewText>
            <I18n
              i18nKey={'k_landing_main_seller_creator_type_card_message_1_v2'}
              i18nOptions={{
                attr1: `class="${boldCss()}"`,
                attr2: `class="${boldCss()}"`,
              }}
            />
          </ReviewText>
        </SampleReviewCard>
        <SampleReviewCard
          reviewer={i18n.t(
            'k_landing_main_seller_creator_type_card_reviewer_2'
          )}
          reviewerLogo={kemiLee}
        >
          <ReviewText>
            <I18n
              i18nKey={'k_landing_main_seller_creator_type_card_message_2_v2'}
              i18nOptions={{
                attr1: `class="${boldCss()}"`,
                attr2: `class="${boldCss()}"`,
              }}
            />
          </ReviewText>
        </SampleReviewCard>
        <LandingButton
          text={i18n.t('k_landing_main_seller_creator_type_button_seller')}
          theme={'black'}
          onClick={handleClickSellerInfoButton}
        />
      </Section>
      <Section theme={'mint'}>
        <SampleImage>
          <Image {...creatorExample} alt={'creatorExample'} />
        </SampleImage>
        <SampleReviewCard
          reviewer={i18n.t(
            'k_landing_main_seller_creator_type_card_reviewer_3'
          )}
          reviewerLogo={kemiJeon}
        >
          <ReviewText>
            <I18n
              i18nKey={'k_landing_main_seller_creator_type_card_message_3_v2'}
              i18nOptions={{
                attr: `class="${boldCss()}"`,
              }}
            />
          </ReviewText>
        </SampleReviewCard>
        <SampleReviewCard
          reviewer={i18n.t(
            'k_landing_main_seller_creator_type_card_reviewer_4'
          )}
          reviewerLogo={kemiMin}
        >
          <ReviewText>
            <I18n
              i18nKey={'k_landing_main_seller_creator_type_card_message_4_v2'}
              i18nOptions={{
                attr: `class="${boldCss()}"`,
              }}
            />
          </ReviewText>
        </SampleReviewCard>
        <LandingButton
          text={i18n.t('k_landing_main_seller_creator_type_button_creator')}
          theme={'black'}
          onClick={handleClickCreatorInfoButton}
        />
      </Section>
    </Container>
  );
};

const Container = styled('div', {
  '@landingTablet': {
    display: 'flex',
    gap: 16,
  },
});

const Section = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flex: 1,
  padding: 24,
  marginBottom: 16,
  borderRadius: 40,

  variants: {
    theme: {
      yellow: {
        backgroundColor: '$yellow100',
      },
      mint: {
        backgroundColor: '$mint100',
      },
    },
  },
});

const SampleImage = styled('div', {
  height: 328,
  width: 320,
  marginBottom: 24,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const ReviewText = styled('p', {
  fontWeight: 400,
  fontSize: 18,
  lineHeight: '21.48px',
  whiteSpace: 'pre-line',
});

const boldCss = css({
  fontWeight: 700,
});

export default SellerAndCreatorType;
