import { ReactNode } from 'react';

import Image from '@global/components/atoms/Image';
import { styled } from '@styles/stitches.config';

type SampleReviewCardProps = {
  children: ReactNode;
  reviewer: string;
  reviewerLogo: {
    src: string;
    width: number;
    height: number;
  };
};

const SampleReviewCard = ({
  children,
  reviewer,
  reviewerLogo,
}: SampleReviewCardProps) => {
  return (
    <SampleReviewCardContainer>
      <div>{children}</div>
      <Reviewer>{reviewer}</Reviewer>
      <ReviewerLogo>
        <Image alt={'reviewer-logo'} {...reviewerLogo} />
      </ReviewerLogo>
    </SampleReviewCardContainer>
  );
};

const SampleReviewCardContainer = styled('div', {
  width: '100%',
  display: 'grid',
  gap: 8,
  padding: 24,
  marginBottom: 16,
  backgroundColor: '$white',
  borderRadius: 16,
});

const Reviewer = styled('p', {
  fontType: 'captionRegular',
  color: '$grey100',
});

const ReviewerLogo = styled('div', {
  width: 36,
  height: 32,
});

export default SampleReviewCard;
