import FAQ from '../../shared/components/FAQ';
import ReadyToStart from '../../shared/components/ReadyToStart';

import CreatorUSP from './CreatorUSP';
import DoYouHaveTroubleBubbles from './DoYouHaveTroubleBubbles';
import KemiData from './KemiData';
import KemiMainPreviews from './KemiMainPreviews';
import MakeHomepageMessage from './MakeHomepageMessage';
import SellerAndCreatorType from './SellerAndCreatorType';
import SellerUSP from './SellerUSP';
import StatisticsFeature from './StatisticsFeature';
import TitleAndLinkNameInput from './TitleAndLinkNameInput';

import { EVENT_TAG } from '@global/constants';
import { useLogFirebase } from '@global/service/logger/EventHandler';
import { UserInteractionType } from '@global/types';
import Header from '@pages/landing/shared/components/Header';
import LandingFooter from '@pages/landing/shared/components/LandingFooter';
import Section from '@pages/landing/shared/components/Section';
import { styled } from '@styles/stitches.config';

const Landing = () => {
  useLogFirebase(
    UserInteractionType.PAGE_IN,
    EVENT_TAG.LANDING.LD_HOME_SCREEN_OPEN
  );

  return (
    <Wrapper>
      <Content>
        <Header />
        <Section>
          <TitleAndLinkNameInput />
        </Section>
        <Section>
          <DoYouHaveTroubleBubbles />
        </Section>
        <Section>
          <SellerAndCreatorType />
        </Section>
        <Section>
          <KemiMainPreviews />
        </Section>
        <Section>
          <KemiData />
        </Section>
        <Section>
          <MakeHomepageMessage />
        </Section>
        <Section>
          <SellerUSP />
        </Section>
        <Section>
          <CreatorUSP />
        </Section>
        <Section>
          <StatisticsFeature />
        </Section>
        <Section>
          <ReadyToStart />
        </Section>
        <Section>
          <FAQ />
        </Section>
      </Content>
      <LandingFooter />
    </Wrapper>
  );
};

const Wrapper = styled('div', {
  fontFamily: 'Pretendard',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  backgroundColor: '$white',
});

const Content = styled('div', {
  width: '400px',
  maxWidth: '100%',
  backgroundColor: '$white',

  '@landingTablet': {
    width: '800px',
  },
  '@landingPC': {
    width: '1200px',
  },
});

export default Landing;
