import Image from '@global/components/atoms/Image';
import i18n from '@i18n/index';
import TitleAndDescription from '@pages/landing/shared/components/TitleAndDescription';
import {
  statisticsFeatureImage1,
  statisticsFeatureImage2,
  statisticsFeatureImage3,
} from '@pages/landing/shared/images';
import { css, styled } from '@styles/stitches.config';
import I18n from 'src/i18n/components/I18n';

const StatisticsFeature = () => {
  return (
    <Container>
      <TitleAndDescription
        title={
          <I18n
            i18nKey={'k_landing_main_kemi_statistics_title_v2'}
            i18nOptions={{
              attr: `class="${blue100Css()}"`,
            }}
          />
        }
        description={i18n.t('k_landing_main_statistics_description')}
      />
      <FeatureCards>
        <FeatureCard>
          <ImageBox>
            <ImageWidthBox>
              <Image
                src={statisticsFeatureImage1}
                alt={'kemi-statistics-image'}
              />
            </ImageWidthBox>
          </ImageBox>
          <Description>
            {i18n.t('k_landing_main_statistics_card_message_1')}
          </Description>
        </FeatureCard>
        <FeatureCard>
          <ImageBox>
            <ImageWidthBox>
              <Image
                src={statisticsFeatureImage2}
                alt={'kemi-statistics-image'}
              />
            </ImageWidthBox>
          </ImageBox>
          <Description>
            {i18n.t('k_landing_main_statistics_card_message_2')}
          </Description>
        </FeatureCard>
        <FeatureCard>
          <ImageBox>
            <ImageWidthBox>
              <Image
                src={statisticsFeatureImage3}
                alt={'kemi-statistics-image'}
              />
            </ImageWidthBox>
          </ImageBox>
          <Description>
            {i18n.t('k_landing_main_statistics_card_message_3')}
          </Description>
          <Remark>{`*${i18n.t('k_scheduled_to_be_introduced')}`}</Remark>
        </FeatureCard>
      </FeatureCards>
    </Container>
  );
};

const Container = styled('div', {
  marginTop: 240,
});

const blue100Css = css({
  color: '$blue100',
});

const FeatureCards = styled('div', {
  display: 'grid',
  gap: 16,
  justifyContent: 'center',
  marginTop: 120,

  '@landingTablet': {
    gridTemplateColumns: 'repeat(2, 1fr)',

    '& > div:nth-child(1)': {
      gridColumn: '1 / 2',
      gridRow: '1 / 3',
    },

    '& > div:nth-child(2)': {
      gridColumn: '2 / 3',
      gridRow: '1 / 2',
    },

    '& > div:nth-child(3)': {
      gridColumn: '2 / 3',
      gridRow: '2 / 3',
    },
  },

  '@landingPC': {
    gridTemplateColumns: 'repeat(3, 1fr)',

    '& > div:nth-child(1)': {
      gridColumn: '1 / 2',
      gridRow: '1 / 2',
    },

    '& > div:nth-child(2)': {
      gridColumn: '2 / 3',
      gridRow: '1 / 2',
    },

    '& > div:nth-child(3)': {
      gridColumn: '3 / 4',
      gridRow: '1 / 2',
    },
  },
});

const FeatureCard = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: 8,
  padding: 24,
  paddingBottom: 64,
  backgroundColor: '$blue50',
  borderRadius: 40,

  '@landingPC': {
    justifyContent: 'flex-start',
  },
});

const ImageBox = styled('div', {
  marginBottom: 24,

  '@landingPC': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 394,
  },
});

const ImageWidthBox = styled('div', {
  '@landingPC': {
    maxWidth: 320,
  },
});

const Description = styled('p', {
  fontType: 'subtitleRegular',
  whiteSpace: 'pre-line',
});

const Remark = styled('span', {
  marginTop: 8,
  fontType: 'captionRegular',
  color: '$grey100',
});

export default StatisticsFeature;
