/* eslint-disable quotes */
import { useState } from 'react';

import { mainImage } from '../images';

import LinkNameInput from './LinkNameInput';

import Image from '@global/components/atoms/Image';
import i18n from '@i18n/index';
import { css, styled } from '@styles/stitches.config';
import I18n from 'src/i18n/components/I18n';

const TitleAndLinkNameInput = () => {
  const [linkName, setLinkName] = useState('');

  return (
    <Container>
      <Section1>
        <Title>
          <I18n
            i18nKey={'k_landing_main_title_v2'}
            i18nOptions={{
              attr1: `class="${teal200Css()}"`,
              attr2: `class="${p100Css()}"`,
            }}
          />
        </Title>
        <MakeKemiBubble>
          <Bubble>
            <span>{i18n.t('k_make_for_free_bubble')}</span>
          </Bubble>
          <Arrow />
        </MakeKemiBubble>
        <LinkNameInput linkName={linkName} onChange={setLinkName} />
      </Section1>
      <Section2>
        <Image alt={'example'} {...mainImage} />
      </Section2>
    </Container>
  );
};

const Container = styled('div', {
  height: 888,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  padding: '0 24px',
  marginTop: 16,

  backgroundColor: '$primary50',
  borderRadius: 40,

  '@landingTablet': {
    height: 480,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

const Section1 = styled('div', {
  display: 'flex',
  flexDirection: 'column',

  '@landingTablet': {
    width: '50%',
    paddingX: 14,
  },

  '@landingPC': {
    paddingX: 114,
  },
});

const Section2 = styled('div', {
  marginBottom: -2,

  '@landingTablet': {
    marginBottom: -18,
    width: 360,
  },
  '@landingPC': {
    marginRight: 100,
  },
});

const Title = styled('h1', {
  fontType: 'heading2',
  whiteSpace: 'pre-line',
  color: '$black',
  marginBottom: 64,
});

const teal200Css = css({
  color: '$teal200',
});

const p100Css = css({
  color: '$purple100',
});

const MakeKemiBubble = styled('div', {
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: 24,
});

const Bubble = styled('div', {
  width: 157,
  height: 34,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '$white',
  borderRadius: 8,
  fontType: 'captionRegular',
});

const Arrow = styled('div', {
  position: 'absolute',
  backgroundColor: '$white',
  top: 34,
  left: 157,
  borderLeft: '8px solid $purple50',
  borderRight: '8px solid $purple50',
  borderTop: '8px solid $white',
});

export default TitleAndLinkNameInput;
