import { LANDING_IMAGE_URL_SHARED } from '@pages/landing/shared/constant';

export const statisticsFeatureImage1 = {
  src: `${LANDING_IMAGE_URL_SHARED}/09-1-statistics-feature-01.png`,
  width: 1101,
  height: 1356,
};

export const statisticsFeatureImage2 = {
  src: `${LANDING_IMAGE_URL_SHARED}/09-2-statistics-feature-02.png`,
  width: 1101,
  height: 1116,
};

export const statisticsFeatureImage3 = {
  src: `${LANDING_IMAGE_URL_SHARED}/09-3-statistics-feature-03.png`,
  width: 1626,
  height: 672,
};
