import { useRouter } from 'next/router';

import { sellerUSPImage1, sellerUSPImage2, sellerUSPImage3 } from '../images';

import { EVENT_TAG } from '@global/constants';
import { logFirebase } from '@global/service/logger/EventHandler';
import useLoginAndSignupModalStore from '@global/store/loginAndSignupModalStore';
import { LoginModalType, UserInteractionType } from '@global/types';
import i18n from '@i18n/index';
import CardList from '@pages/landing/shared/components/CardList';
import LandingButton from '@pages/landing/shared/components/LandingButton';
import TitleAndDescription from '@pages/landing/shared/components/TitleAndDescription';
import { getLandingLogData } from '@pages/landing/shared/helper';
import { styled } from '@styles/stitches.config';

const SellerUSP = () => {
  const router = useRouter();
  const { open } = useLoginAndSignupModalStore();
  const sellerUSPData = [
    {
      imageSrc: sellerUSPImage1,
      title: i18n.t('k_landing_main_seller_usp_card_title_1'),
      description: i18n.t('k_landing_main_seller_usp_card_description_1'),
    },
    {
      imageSrc: sellerUSPImage2,
      title: i18n.t('k_landing_main_seller_usp_card_title_2'),
      description: i18n.t('k_landing_main_seller_usp_card_description_2'),
    },
    {
      imageSrc: sellerUSPImage3,
      title: i18n.t('k_landing_main_seller_usp_card_title_3'),
      description: i18n.t('k_landing_main_seller_usp_card_description_3'),
    },
  ];

  const handleClickStartKemi = () => {
    logFirebase(
      UserInteractionType.CLICK,
      EVENT_TAG.LANDING.LD_HOME_FREESTART_CLICK,
      getLandingLogData()
    );

    open({ type: LoginModalType.signup, redirectTo: router.asPath });
  };

  return (
    <Container>
      <TitleAndDescription
        title={i18n.t('k_landing_main_seller_usp_title')}
        description={i18n.t('k_landing_main_seller_usp_description')}
      />
      <CardList
        gridType={'twoRowsInFirstColumn'}
        bgColor={'yellow'}
        contentOrder={'imageTitleDescription'}
        contentGap={24}
        cards={sellerUSPData}
      />
      <ButtonBox>
        <LandingButton
          text={i18n.t('k_get_started_now_for_free')}
          theme={'black'}
          onClick={handleClickStartKemi}
        />
      </ButtonBox>
    </Container>
  );
};

const Container = styled('div', {
  marginTop: 240,
});

const ButtonBox = styled('div', {
  width: '100%',
  maxWidth: 400,
  paddingX: 32,
  margin: '0 auto',
});

export default SellerUSP;
