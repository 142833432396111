import { profitGIF, sellerGIF, visitorGIF } from '../images';

import i18n from '@i18n/index';
import CardList from '@pages/landing/shared/components/CardList';

const KemiData = () => {
  const kemiDataCard = [
    {
      imageSrc: sellerGIF,
      description: i18n.t('k_companion_sellers'),
      title: `3,000${i18n.t('k_person_count')}+`,
    },
    {
      imageSrc: profitGIF,
      description: i18n.t('k_average_earnings_per_market'),
      title: `4,000,000${i18n.t('k_price_suffix')}+`,
    },
    {
      imageSrc: visitorGIF,
      description: i18n.t('k_cumulative_number_of_visitors'),
      title: `250,000${i18n.t('k_person_count')}+`,
    },
  ];
  return (
    <CardList
      gridType={'twoRowsInFirstColumn'}
      bgColor={'purple'}
      contentOrder={'imageDescriptionTitle'}
      contentGap={8}
      cardAlign={'center'}
      contentPadding={64}
      cards={kemiDataCard}
    />
  );
};
export default KemiData;
