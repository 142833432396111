import { kemiExample } from '../images';

import Image from '@global/components/atoms/Image';
import i18n from '@i18n/index';
import TitleAndDescription from '@pages/landing/shared/components/TitleAndDescription';
import { styled } from '@styles/stitches.config';

const MakeHomepageMessage = () => {
  return (
    <Container>
      <TitleAndDescription
        title={i18n.t('k_create_hompage_easily')}
        description={i18n.t('k_express_yourself_with_various_cards')}
      />
      <ImageContainer>
        <Image {...kemiExample} alt={'kemi-example'} />
      </ImageContainer>
    </Container>
  );
};

const Container = styled('div', {
  marginTop: 120,
});

const ImageContainer = styled('div', {
  marginTop: 120,

  '@landingTablet': {
    width: '50%',
    margin: '120px auto 0px auto',
  },

  '@landingPC': {
    width: '33%',
    margin: '120px auto 0px auto',
  },
});

export default MakeHomepageMessage;
